.mybutton:hover {
    background-color: rgba(0, 0, 0, 0);  
}

.containerFilter {
  background-color: blueviolet;
  display: grid;
  grid-template-columns: 1fr;  
}



.containerChildrenFilter {
  background-color: '#CCC';

}